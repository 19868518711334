import Typography from "typography"
import lawtonTheme from "typography-theme-lawton"
import fairyTaleGates from "typography-theme-fairy-gates"

const typography = new Typography({
  baseFontSize: "15%",
  baseLineHeight: 1.5,
  googleFonts: [
    {
      name: "Raleway",
      styles: ["800"],
    },
    {
      name: "flama",
      styles: ["400", "400i", "700"],
    },
  ],
  headerFontFamily: ["Raleway", "sans-serif"],
  bodyFontFamily: ["Libre Baskerville", "sans-serif"],
  headerColor: "white",
  bodyColor: "white",
  headerWeight: 800,
  bodyWeight: 400,
  boldWeight: 700,
  blockMarginBottom: 2 / 3,
})

// const typography = new Typography(lawtonTheme)

export const { scale, rhythm, options } = typography
export default typography
