import React from "react"
import { Link } from "gatsby"
import typography from "../utils/typography"

import espago_img from "../images/espago_min.jpg"
import gotstuff_img from "../images/gotstuff_min.jpg"
import mh_img from "../images/muscle_hustle_min.jpg"
import human_atlas_img from "../images/human_atlas_min.jpg"
import resnet_img from "../images/resnet.png"
import densenet_img from "../images/densenet.png"

import Container from "../components/container"
import { Column, Row } from "../components/columnAndRow"
import Layout from "../components/layout"
import SEO from "../components/seo"

const GridRow = ({ displayTxt, gridImg, linkTo }) => {
  return (
    <Row>
      <Column>
        <div
          className="gridButton"
          style={{
            background: `linear-gradient(0deg, rgba(14, 18, 25, 0.25), rgb(14, 18, 25)), url(${
              gridImg[0]
            }) no-repeat top`,
            backgroundSize: "180%",
          }}
        >
          <a href={linkTo[0]} target="_blank">
            <h2>{displayTxt[0]}</h2>
          </a>
        </div>
      </Column>
      <Column>
        <div
          className="gridButton"
          style={{
            background: `linear-gradient(0deg, rgba(14, 18, 25, 0.25), rgb(14, 18, 25)), url(${
              gridImg[1]
            }) no-repeat center`,
            backgroundSize: "180%",
          }}
        >
          <a href={linkTo[1]} target="_blank">
            <h2>{displayTxt[1]}</h2>
          </a>
        </div>
      </Column>
    </Row>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="TASE Industries"
      keywords={[`tase`, `industries`, `development`]}
    />
    <Container>
      <div style={{ color: "#71737a", margin: "5%" }}>
        <h4>
          We're constantly building, designing and creating over here at TASE
          Industries. Some of our previous projects:
        </h4>
      </div>
      <GridRow
        displayTxt={["eSpaGo", "GotStuff"]}
        gridImg={[espago_img, gotstuff_img]}
        linkTo={[
          "https://espago.herokuapp.com/",
          "https://abinv-client.herokuapp.com/",
        ]}
      />
      <GridRow
        displayTxt={[
          "Muscle Hustle",
          "Neural Network Microscopy Classification",
        ]}
        gridImg={[mh_img, human_atlas_img]}
        linkTo={[
          "https://obscure-inlet-90462.herokuapp.com/",
          "https://github.com/syiin/human_protein_atlas/blob/master/Mk9%20SENet_1FC.ipynb",
        ]}
      />
      <GridRow
        displayTxt={[
          "ResNets For Cancer Detection",
          "DenseNet For Cancer Detection",
        ]}
        gridImg={[resnet_img, densenet_img]}
        linkTo={[
          "https://github.com/syiin/ann_cancer_detection/blob/master/resnet18_34_50_101_152.ipynb",
          "https://github.com/syiin/ann_cancer_detection/blob/master/densenet.ipynb",
        ]}
      />
    </Container>
  </Layout>
)

export default IndexPage

/*

background: linear-gradient(0deg, rgba(14, 18, 25, 0.25), rgb(14, 18, 25)), url("/images/facerecog.jpg"); position: absolute; left: 0px; top: 256px; transition-property: opacity, transform; transition-duration: 0.4s; transition-delay: 0ms; transform: translate3d(0px, 768px, 0px);
*/
