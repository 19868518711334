import React from "react"
import columnAndRowStyles from "./columnAndRow.module.css"

const Column = ({ children }) => (
  <div className={columnAndRowStyles.column}>{children}</div>
)

const Row = ({ children }) => (
  <div className={columnAndRowStyles.row}>{children}</div>
)

export { Column, Row }
